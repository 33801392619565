@import "../../themes.scss";

.FullScreen {
  width: 100%;
  min-height: calc(100vh);
  align-items: flex-start;
  background-color: whitesmoke;
  background-image: url("../../assets/images/mtn_bg.jpg");
  background-size: cover;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
}

.LeftRowItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CenterItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightRowItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .LeftRowItem {
    justify-content: flex-end;
    //padding-right: 10%;
  }
  .RightRowItem {
    justify-content: flex-start;
    //transform: translateY(45px);
    //padding-left: 40px;
  }
}

.LogoImage {
  //height: 100px;
  width: 90%;
  max-width: 400px;
  padding: 5px;
  text-align: center;
  margin-right: 10px;

  //margin-bottom: 20px;
  // border-color: red;
  // border-width: 2;
  // border-style: solid;
  //width: $titleColor;
  //font-family: $titleFont;
  //font-size: 34px;
}

.LogoText {
  margin-bottom: 10px;
  //color: #9125f2;
  color: $mainColor;
  font-family: $titleFont;
  font-size: 60px;
  opacity: 0;
  transition: 1000ms all;
  //display: flex;
  //align-items: center;
  //justify-content: flex-start;
}

.QuestionText {
  //color: $mainColor;
  color: white;
  font-size: 32px;
  font-family: $workingFont;
  font-weight: lighter;
  transform: translateY(-50px);
}

.AnswerText {
  color: $mainColor;
  background-color: white;
  //background-color: $mainColor;
  //color: white;
  //background-color: rgb(107, 33, 156);
  font-size: 28px;
  font-weight: bold;
  font-family: $workingFont;
  padding: 20px;
  //   padding: 10px;
  //   padding-left: 20px;
  //   padding-right: 20px;
  //   border: solid 1px $mainColor;
  border-radius: 15px;
  //display: flex;
  //width: 310px;
  //height: 115px;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.AnswerText:hover {
  cursor: pointer;
  .AnswerSubtext {
    display: none;
  }
  .AnswerSubicon {
    display: block;
  }
}

.AnswerText:active {
  background-color: whitesmoke;
  .AnswerSubtext {
    display: none;
  }
  .AnswerSubicon {
    display: block;
  }
}

.AnswerSubtext {
  //color: black;
  //font-weight: lighter;
  font-size: 12px;
  opacity: 0.7;
  color: #444444;
  font-weight: normal;
  width: 245px;
  //font-family: $inputFont;
  //margin-top: 5px;
}

.AnswerSubicon {
  font-size: 12px;
  //opacity: 0.7;
  display: none;
  width: 245px;
}

.SpinnerDiv {
  font-size: 12px;
  //opacity: 0.7;
  width: 245px;
}
