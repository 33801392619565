@import "~bootstrap/scss/bootstrap";
@import "./themes.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  //background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //font-size: calc(10px + 2vmin);
  //color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn:focus,
.btn:active {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: none !important;
}

.Collapsible__trigger {
  align-items: space-between;
  flex-direction: row;
  display: block;
  //flex-direction: column;
  text-align: left;
  background-color: white;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 15px;
  margin-top: 15px;
  color: #444444;
  width: 100%;
  font-size: 20px;
}

.Collapsible__trigger:hover {
  cursor: pointer;
}
//*******************************************

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23444444' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23444444' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-inner {
  //margin-left: 20px;
  //margin-bottom: 20px;

  // text-align: center;
  // justify-content: center;
  // display: flex;
  // flex-direction: row;
}

.carousel-indicators li {
  // margin-top: 20px;
  // display: inline-block;
  // width: 48px;
  // height: 48px;
  // margin: 10px;
  // text-indent: 0;
  // cursor: pointer;
  border: none;
  //border-radius: 50%;
  background-color: #444444;

  //box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.carousel-indicators .active {
  // width: 48px;
  // height: 48px;
  // margin: 10px;
  background-color: $mainColor;
}
.carousel-indicators {
  // width: 48px;
  // height: 48px;
  // margin: 10px;
  margin-top: 30px;
  transform: translateY(10px);
}
// .carousel-item:active {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
