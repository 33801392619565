@import "../../themes.scss";

.ViewMoreButton {
  color: "white";
  //color: $mainColor;
  //background-color: white;
  //background-color: rgba(107, 33, 156, 0.85);
  font-size: 16px;
  font-weight: bold;
  font-family: $workingFont;
  padding: 3px;
  padding-left: 12px;
  padding-right: 12px;
  //padding: 20px;
  //   padding: 10px;
  //   padding-left: 20px;
  //   padding-right: 20px;
  //border: solid 2px $mainColor;
  border-radius: 8px;
  //display: flex;
  //width: 250px;
  //height: 115px;
  //margin-top: 40px;
  //display: flex;
  //flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  align-self: center;
}

.ViewMoreButton:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.CaseStudyCard {
  // border-style: solid;
  // border-width: 1;
}

.CaseStudyCard:hover {
  cursor: pointer;
  opacity: 0.7;
}

.LogoImage {
  //height: 100px;
  //margin-top: 10px;
  width: 90%;
  max-width: 400px;
  padding: 5px;
  text-align: center;
  //margin-right: 10px;

  //margin-bottom: 20px;
  // border-color: red;
  // border-width: 2;
  // border-style: solid;
  //width: $titleColor;
  //font-family: $titleFont;
  //font-size: 34px;
}

.LogoText {
  //margin-bottom: 10px;
  //color: #9125f2;
  color: $mainColor;
  font-family: $titleFont;
  font-size: 55px;
  opacity: 0;
  transition: 1000ms all;
  //display: flex;
  //align-items: center;
  //justify-content: flex-start;
}

.Carousel {
  width: 250px;
  color: #444444;
  //height: 250px;
}
